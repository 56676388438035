html, body {
  width: 100%;
  height: 100%;
}

body {
  overflow-x: hidden;
  font-family: $body-font-family;
  color: palette('ci-dark-gray');
}



h1, h2, h3, h4, h5, h6 {
  font-family: $header-font-family;
  color: palette('ci-dark-gray');
}

p, label, button, a {
  font-size: 14px;
}