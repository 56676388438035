
/* New CI buttons
--------------------------------- */
.btn-primary {
  border-radius: 3px;
  color: $white;
  background-color:  palette('ci-deep-blue');
  font-weight: 700;
  display: inline-block;
  border: none;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
  text-align: center;
  font-size: 14px;

  &:hover,
  &:visited,
  &:active,
  &:focus {
    background-color:  palette('ci-deep-blue')!important;
    color: $white;
    border: none;
    box-shadow: none;
  }
}

.btn-link {
  border-radius: 3px;
  color:  palette('ci-deep-blue');
  background: transparent;
  font-weight: 400;
  display: inline-block;
  border: none;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
  text-align: center;
  font-size: 14px;


  &:hover,
  &:visited,
  &:active,
  &:focus {
    background-color: transparent;
    color: palette('ci-deep-blue');
    text-decoration: none;
  }
}
.btn-danger {
  border-radius: 3px;
  color: $white;
  background-color: palette('ci-red');
  font-weight: 700;
  display: inline-block;
  border: none;
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
  text-align: center;
  font-size: 14px;


  &:hover,
  &:visited,
  &:active,
  &:focus {
    background-color: palette('ci-red')!important;
    color: $white;
    border: none;
    box-shadow: none;
  }
}

.btn-outline-danger {
  border-radius: 3px;
  color: palette('ci-red');
  border: solid 1px palette('ci-silver');
  background: $white;
  font-weight: 700;
  display: inline-block;
  height: 32px;
  line-height: 30px;
  padding: 0 20px;
  text-align: center;
  font-size: 14px;


  &:hover,
  &:visited,
  &:active,
  &:focus {
    color: palette('ci-red')!important;
    background-color: $white!important;
    border: solid 1px palette('ci-silver')!important;
    box-shadow: none!important;
    outline: 0 none!important;
  }
}

.btn-outline-primary {
  border-radius: 3px;
  color: palette('ci-deep-blue');
  border: solid 1px palette('ci-silver');
  background: $white;
  font-weight: 400;
  display: inline-block;
  height: 32px;
  line-height: 30px;
  padding: 0 20px;
  text-align: center;
  font-size: 14px;


  &:hover,
  &:visited,
  &:active,
  &:focus {
    color: palette('ci-deep-blue')!important;
    background-color: $white!important;
    border: solid 1px palette('ci-silver')!important;
    box-shadow: none;
    outline: 0 none!important;
  }
}

.btn-filter {
  background-color: transparent;
  border: none;
  color: palette('ci-transparent-dark-gray');
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 700;
  height: 35px;
  border-radius: 3px;


  svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;

    path {
      fill: palette('ci-transparent-dark-gray');
    }
  }

  span {
    vertical-align: middle;
    line-height: 1;
  }

  &:hover,
  &:visited,
  &:active,
  &:focus,
  &.active {
    border: none;
    box-shadow: none!important;
    outline: 0 none!important;
    background-color: #f3f5f6!important;
    color: palette('ci-dark-gray')!important;

    svg {
      path {
        fill: palette('ci-dark-gray');
      }
    }
  }
}

.btn-primary, .btn-link, .btn-danger, .btn-outline-danger, .btn-outline-primary {
  &:disabled {
    cursor: not-allowed;
  }
}

/* Button list
--------------------------------- */
.btn-list {
  border-radius: 3px;
  background-color: palette('ci-pale-gray');
  border: 1px solid palette('ci-silver');
  display: inline-block;

  .btn {
    width: 100px;
    height: 33px;
    border-radius: 2px;
    line-height: 1;
    background-color: palette('ci-pale-gray');
    color: palette('ci-transparent-dark-gray');
    border-right: 1px solid palette('ci-silver');

    &.active,
    &:focus {
      background-color: palette('ci-blue-gray-transparent');
      color: palette('ci-dark-gray');
      box-shadow: none;
    }
  }
}
