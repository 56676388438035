$ci-palette : (
        ci-blue: #374bbf,
        ci-deep-blue: #0086ff,
        ci-transparent-white: rgba(255,255,255,0.65),
        ci-yellow: #f4b941,
        ci-light-gray: #edf3f8,
        ci-pale-gray: #f8fafc,
        ci-black: #2c3033,
        ci-dark-gray: #1d364b,
        ci-gray: #8a8a8a,
        ci-blue-gray: #8ea0b1,
        ci-blue-gray-transparent: rgba(142, 160, 177, 0.15),
        ci-medium-gray: #cccccc,
        ci-transparent-dark-gray: rgba(29, 54, 75, .5),
        ci-transparent-black: rgba(0, 0, 0, .2),
        ci-silver: #d3d7db,
        ci-red: #ff5349,
        ci-green: #38c089,
        ci-max-red: #E74C3C,
        ci-max-green: #2ECC71,
        ci-orange: #f39c12,
        graph-yellow: #f1c40f,
        graph-orange: #f39c12,
);

$white: #fff;


// Fonts
$body-font-family: "nimbus-sans", Helvetica, Arial, sans-serif;
$header-font-family: "futura-pt", Helvetica, Arial, sans-serif;
$font-size-base: 0.875rem;
$font-color-main: palette('ci-dark-gray');