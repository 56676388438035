/* Custom Checkbox and Radio
--------------------------------- */
.ci-control {
  position: relative;
  padding-left: 30px;
  padding-top: 2px;
  margin-bottom: 7px;
  cursor: pointer;
  padding-right: 0;
  margin-right: 10px;
  text-align: left;

  input {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
}

.ci-control-indicator {
  position: absolute;
  top: 2px;
  left: 0;
  height: 20px;
  width: 20px;
  background: palette('ci-pale-gray');
  border: 1px solid palette('ci-silver');
}

.ci-control-radio .ci-control-indicator {
  border-radius: 50%;
}

.ci-control input:checked ~ .ci-control-indicator {
  background: palette('ci-deep-blue');
  border: none;
}

.ci-control:hover input:not([disabled]):checked ~ .ci-control-indicator,
.ci-control input:checked:focus ~ .ci-control-indicator {
  background: palette('ci-deep-blue');
}

.ci-control input:disabled ~ .ci-control-indicator {
  background: darken(palette('ci-pale-gray'), 10%);
  opacity: 0.6;
  pointer-events: none;
  cursor: not-allowed;
}

.ci-control-indicator:after {
  content: '';
  position: absolute;
  display: none;
}

.ci-control input:checked ~ .ci-control-indicator:after {
  display: block;
}

.ci-control-checkbox .ci-control-indicator:after {
  left: 4px;
  top: 0;
  content: url('/images/checked-white.svg');;
}

.ci-control-radio .ci-control-indicator:after {
  left: 6px;
  top: 6px;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: $white;
}


input.form-control {
  width: 100%;
  height: 35px;
  border: 1px solid palette('ci-silver');
  background-color: palette('ci-pale-gray');
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  &::placeholder {
    font-size: $font-size-base;
    color: palette('ci-blue-gray');
  }
}

select.form-control {
  height: 35px;
  border-radius: 3px;
  background: palette('ci-pale-gray');
  border: 1px solid palette('ci-silver');
  color: palette('ci-blue-gray');
  font-size: 14px;
}

.invalid-db-error {
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.form-control {
  &:focus {
    box-shadow: none;
    border-color: palette('ci-silver');
    background-color: palette('ci-pale-gray');
  }
}

/* react-select
--------------------------------- */
// Normal select
div.ci-select__control {
  min-height: 35px !important;
  height: 35px;
  border-radius: 3px;
  background: palette('ci-pale-gray');
  border: 1px solid palette('ci-silver');
  color: palette('ci-blue-gray');

  div.ci-select__placeholder {
    color: palette('ci-blue-gray');
    line-height: 32px;
  }

  div.ci-select__single-value {
    line-height: 1;
  }

}

div.ci-select__menu {
  div.ci-select__option {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

// Multi select
.basic-multi-select {
  div.ci-select__control {
    height: auto;

    .ci-select__multi-value {
      background-color: palette("ci-blue-gray");

      .ci-select__multi-value__label {
        color: $white;
        font-weight: 700;
        font-family: $header-font-family;
      }

      .ci-select__multi-value__remove {
        svg {
          path {
            fill: $white;
          }
        }
      }
    }
  }
}
