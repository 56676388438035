.main-nav {
  padding-left: 200px;
  padding-right: 200px;
  .nav-item {
    .nav-link {
      padding: 2rem;
      font-family: $header-font-family;
      text-transform: uppercase;
      font-weight: 700;
      color: palette('ci-dark-gray');
      transition: background-color .3s;


      &:hover {
        background-color: darken(#f8f9fa, 5%);
        transition: background-color .3s;
      }
    }
  }


}