.cr-page .cr-wizard-profile-details {
  margin-bottom: 20px;
  margin-top: 80px;
  width: 100%;
}

.page-title h1 {
  display: inline-block;
  font-size: 60px;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.025em;
}

.page-subtitle {
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 40px;
}


.cr-page {
  padding-bottom: 140px;
  .cr-form {
    width: 220px;
    display: inline-block;
    .ci-control-checkbox {
      display: block;
    }

    .btn {
      width: 100%;
    }
  }

  .cr-preview {
    width: 860px;
    display: inline-block;
    margin-left: 60px;

    h3 {
      display: inline-block;
    }

    .form-group {
      display: inline-block;
      float: right;
    }

    .cr-preview-box {
      height: 100%;
      width: 100%;
      border: 1px solid palette('ci-silver');
      border-radius: 3px;
      clear: both;
      transition: width .3s;

      iframe {
        min-height: 640px;
        height: 100%;
        display: block;
        width: 100%;
        border: none;
      }
    }

    .cr-preview-box-mobile {
      width: 320px;
      margin: 0 auto;
      transition: width .3s;
    }
  }


  .cr-code-preview {
    width: 860px;
    margin-left: auto;
    background: palette('ci-pale-gray');
    border: 1px solid palette('ci-light-gray');
    border-radius: 4px;
    -moz-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);

    .cr-code {
      padding: 20px 60px;
      span {
        display: block;
        padding-left: 20px;
        font-family: monospace;
        font-size: 15px;
      }

      span:first-child {
        padding-left: 0;
      }

      span:last-child {
        padding-left: 0;
      }

      .meta {
        padding-left: 40px;
      }
    }

  }

  .example-code-title {
    width: 860px;
    margin-top: 90px;
    margin-left: auto;
    margin-bottom: 20px;
  }
}